import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { SxProps, Theme, lighten } from "@mui/material/styles";
import { useTheme } from "@cbex/utils/theme";

export type CustomTextFieldProps = TextFieldProps & {
  controldisabled?: boolean;
  useFullWidth?: boolean;
  size?: string;
  sx?: SxProps<Theme>;
};

export const CustomTextField = ({
  size = "small",
  useFullWidth = true,
  controldisabled,
  variant = "filled",
  value,
  sx,
  ...props
}: CustomTextFieldProps) => (
  <TextField
    inputProps={{ readOnly: controldisabled }}
    fullWidth={useFullWidth ? true : false}
    disabled={controldisabled}
    value={value || ""}
    size={size}
    variant={variant}
    sx={sx}
    {...props}
  />
);

export default CustomTextField;
