/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import {
  DataGridPro,
  useGridApiRef,
  GridSortModel,
  DataGridProProps,
  GridRowModel,
  GridFooter,
  nlNL,
  // DataGridProProps,
} from "@mui/x-data-grid-pro";
import { QuickSearchToolbar } from "../lib/QuickSearchToolbar";
import { lighten, Stack } from "@mui/material";
import NoDataComponent from "./NoDataComponent";
import { useTheme } from "@cbex/utils/theme";

type DataGridComponentProps = DataGridProProps & {
  rowData: any;
  columns?: any;
  rows?: any;
  columnData?: any;
  initialState?: any;
  enableCheckbox: boolean;
  exportEnabled?: boolean;
  height?: number | string;
  searchenabled?: boolean;
  sortModel?: GridSortModel;
  width?: number | string;
  onRowEdit?: any;
  rowHeight?: number;
  getCustomID?: any;
  handleSelectedRows?: any;
  onCheckboxSelectionComplete?: boolean;
  handleContextMenu?: any;
  handleContextMenuRow?: any;
  footer?: any;
  singleRowClick?: boolean;
  selectionModel?: any;
  footerData?: any;
  checkBoxValue?: boolean;
  checkBoxLabel?: string;
  onCheckBoxClicked?: () => void;
  SecondcheckBoxValue?: boolean;
  SecondcheckBoxLabel?: string;
  onSecondCheckBoxClicked?: () => void;
  columnVisibilityModel?: any;
};

export const DataGridComponent = function (props: DataGridComponentProps) {
  const {
    enableCheckbox,
    columnData,
    initialState,
    rowData,
    height,
    width,
    onRowEdit,
    sortModel,
    handleSelectedRows,
    rowHeight,
    onCheckboxSelectionComplete,
    handleContextMenu,
    searchenabled,
    footer,
    handleContextMenuRow,
    footerData,
    getCustomID,
    singleRowClick,
    checkBoxLabel,
    checkBoxValue = false,
    onCheckBoxClicked,
    SecondcheckBoxLabel,
    SecondcheckBoxValue = false,
    onSecondCheckBoxClicked,
    selectionModel,
    exportEnabled,

    columnVisibilityModel,
  } = props;

  const apiRef = useGridApiRef();
  const [checkBoxState, setCheckboxState] = React.useState(
    checkBoxValue || false
  );
  const [secondCheckBoxState, setSecondCheckboxState] = React.useState(
    SecondcheckBoxValue || false
  );
  const [rows, setRows] = React.useState<any[]>(rowData || []);
  const theme = useTheme();
  React.useEffect(() => {
    //@ts-ignore
    setCheckboxState(checkBoxValue);
  }, [checkBoxValue]);

  React.useEffect(() => {
    //@ts-ignore
    setSecondCheckboxState(SecondcheckBoxValue);
  }, [SecondcheckBoxValue]);

  React.useEffect(() => {
    setRows(rowData);
  }, [rowData]);

  React.useEffect(() => {
    if (onCheckboxSelectionComplete) {
      apiRef.current.setRowSelectionModel([]);
    }
  }, [apiRef, onCheckboxSelectionComplete]);

  const handleRowEditCommit = React.useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      onRowEdit(newRow, oldRow);
      apiRef.current.stopRowEditMode({ id: oldRow.id });
      return newRow;
    },
    [apiRef, onRowEdit]
  );

  return (
    <Stack
      id="DataGridComponent"
      width={width ? width : "100%"}
      height={height ? height : "100%"}
    >
      <DataGridPro
        localeText={
          nlNL.components.MuiDataGrid.defaultProps.localeText || undefined
        }
        {...props}
        sx={{
          //   borderRadius: 0,
          //   borderLeft: 0,
          //   borderRight: 0,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.appColors.surfaceContainerLow,
          },
          //   "& .MuiDataGrid-footerContainer": {
          //     // backgroundColor: lighten(theme.appColors.dataGridHeader, 0.4),
          //   },

          //   "& .MuiDataGrid-row.Mui-selected ": {
          //     backgroundColor: lighten(theme.palette.primary.light, 0.4),
          //   },
          //   "& .MuiDataGrid-row.Mui-selected:hover ": {
          //     backgroundColor: lighten(theme.palette.primary.light, 0.2),
          //   },
          //   "& .MuiDataGrid-row:hover": {
          //     backgroundColor: lighten(theme.palette.primary.light, 0.2),
          //   },
          //   "& .MuiDataGrid-cell:focus": {
          //     outline: "none",
          //   },
        }}
        initialState={initialState}
        apiRef={apiRef}
        rowSelectionModel={selectionModel}
        rowHeight={rowHeight}
        getRowId={getCustomID ? getCustomID : undefined}
        onProcessRowUpdateError={(errror: any) =>
          console.log("error updating", errror)
        }
        processRowUpdate={handleRowEditCommit}
        slots={{
          toolbar: searchenabled ? QuickSearchToolbar : null,
          footer: footer ? footer : GridFooter,
          noRowsOverlay: NoDataComponent,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            //@ts-ignore
            onCheckBoxClicked: () => onCheckBoxClicked(),
            checkBoxValue: checkBoxState,
            checkBoxLabel: checkBoxLabel,
            SecondcheckBoxValue: secondCheckBoxState,
            SecondcheckBoxLabel: SecondcheckBoxLabel,
            //@ts-ignore
            onSecondCheckBoxClicked: () => onSecondCheckBoxClicked(),
            exportEnabled: exportEnabled,
          },
          cell: {
            onContextMenu: handleContextMenu,
          },
          row: {
            onContextMenu: handleContextMenuRow,
          },

          footer: footer
            ? {
                //@ts-ignore
                tablewidth: width,
                customtoolbardata: footerData,
              }
            : {},
        }}
        checkboxSelection={enableCheckbox}
        onRowSelectionModelChange={(ids) => {
          if (singleRowClick) {
            handleSelectedRows(ids);
          } else {
            if (ids) handleSelectedRows(ids);
          }
        }}
        disableRowSelectionOnClick={singleRowClick ? false : true}
        disableMultipleRowSelection={singleRowClick ? true : false}
        rows={rows || []}
        //columns={columnData}
        columnVisibilityModel={columnVisibilityModel}
        editMode="row"
      />
    </Stack>
  );
};

export default DataGridComponent;
