import React, { ReactNode } from "react";
import { Typography, TypographyProps, Box } from "@mui/material";

type CustomTypographyComponentProps = TypographyProps & {
  children?: ReactNode;
};

export const CustomTypographyComponent = (
  props: CustomTypographyComponentProps
) => (
  <Box id="CustomTypographyComponent">
    <Typography {...props} />
  </Box>
);

export default CustomTypographyComponent;
