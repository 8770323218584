import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DialogContent } from "@mui/material";

interface CustomAlertDialogProps {
  open: boolean;
  children?: React.ReactNode;
  heading: string;
  useFullWidth?: boolean;
  leftButtonHeading: string;
  rightButtonHeading: string;
  handleClosePressed: () => void;
  handleCancel: () => void;
}

export const CustomDialog = (props: CustomAlertDialogProps) => {
  const {
    open,
    heading,
    handleClosePressed,
    handleCancel,
    children,
    leftButtonHeading,
    rightButtonHeading,
  } = props;
  const [openDialog, setOpenDialog] = React.useState(open);

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    handleClosePressed();
  };

  const handleCancelPressed = () => {
    handleCancel();
  };

  return (
    <Box id="CustomDialog">
      <Dialog
        open={openDialog}
        // fullWidth={true}
        maxWidth="xl"
        onClose={handleCancelPressed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelPressed}>{leftButtonHeading}</Button>
          <Button onClick={handleClose} autoFocus>
            {rightButtonHeading}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default CustomDialog;
