/* eslint-disable func-style */
import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslations } from "next-intl";
import { useWindowDimensions } from "@cbex/utils/hooks";
import { useTheme } from "@cbex/utils/theme";

/* eslint-disable-next-line */
interface customModalProps {
  modalTitle: string;
  openModal: boolean;
  closeHandler: (event?: any, reason?: string) => void;
  children: React.ReactNode;
  minWidth?: number | string;
  disableEscapeKey?: boolean;
  editEnabled?: boolean;
  incomingWidth?: number | string;
  incomingHeight?: number | string;
}

const style = {
  position: "absolute" as "absolute",
  top: "40px",
  left: "50%",
  transform: "translate(-50%, 0)",
  bgcolor: "#F2F2F2",
  boxShadow: 24,
};

export function CustomModal(props: customModalProps) {
  const {
    openModal,
    closeHandler,
    modalTitle,
    minWidth,
    editEnabled,
    disableEscapeKey,
    children,
    incomingWidth,
    incomingHeight,
  } = props;
  const t = useTranslations();
  const handleClose = (event: any, reason: string) => {
    closeHandler(event, reason);
  };
  const { height, width } = useWindowDimensions();
  const theme = useTheme();
  const handleClosePressed = () => {
    closeHandler("", "closeIconPressed");
  };
  return (
    <Modal
      open={openModal}
      disableEscapeKeyDown={disableEscapeKey}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        id="CustomModal2"
        sx={style}
        minWidth={minWidth ? minWidth : "inherit"}
        borderRadius={1}
        width={incomingWidth ? incomingWidth : width ? width - 150 : "80vh"}
        maxWidth={1400}
      >
        <Stack direction="row" padding={2}>
          <Stack width="100%" direction="row">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h4"
              width={"100%"}
            >
              {modalTitle}
            </Typography>
            {editEnabled && (
              <Typography
                variant="body1"
                component="h4"
                alignSelf="center"
                ml={2}
                color={theme.appColors.error}
              >
                {t("editMode")}
              </Typography>
            )}

            <Stack direction="row-reverse" alignItems="center">
              <IconButton
                onClick={handleClosePressed}
                sx={{
                  width: "19px",
                  height: "19px",
                }}
              >
                <CloseIcon sx={{ color: "action.active", fontSize: 18.67 }} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>

        <Divider />
        <Stack
          id="CustomModalInnerStack"
          width="100%"
          maxHeight={
            incomingHeight ? incomingHeight : height ? height - 100 : "100%"
          }
          padding={2}
        >
          {children}
        </Stack>
      </Stack>
    </Modal>
  );
}

export default CustomModal;
