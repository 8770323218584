import React, { ReactNode } from "react";
import { Checkbox, CheckboxProps } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@cbex/utils/theme";
type CustomCheckBoxComponentProps = CheckboxProps & {
  controldisabled?: boolean;
  children?: ReactNode;
  color?: string;
};

const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-disabled": {
    color:
      //@ts-ignore
      theme.palette.primary.main,
    pointerEvents: "unset",
    "&&:hover": {
      cursor: "default",
    },
  },
}));

export const CustomCheckBoxComponent = ({
  controldisabled = false,
  color,
  ...props
}: CustomCheckBoxComponentProps) => {
  const theme = useTheme();
  return (
    <CustomCheckBox
      size="small"
      theme={theme}
      {...props}
      disableRipple={controldisabled}
      disabled={controldisabled}
    />
  );
};

export default CustomCheckBoxComponent;
