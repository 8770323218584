import React from "react";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Stack } from "@mui/material";

interface CustomToolbarProps {
  showExport?: boolean;
  showDensity?: boolean;
  showFilter?: Boolean;
  showColumns?: Boolean;
  showQuickFilter?: boolean;
  children?: React.ReactNode;
}

export const CustomToolbar = ({
  children,
  showColumns,
  showDensity,
  showExport,
  showQuickFilter,
  showFilter,
}: CustomToolbarProps) => (
  <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
    <Stack spacing={2} direction="row" alignSelf={"flex-end"}>
      {showQuickFilter && <GridToolbarQuickFilter />}
      {children}
    </Stack>
    <Stack id="mainItems" direction={"row"}>
      {showColumns && <GridToolbarColumnsButton />}
      {showFilter && <GridToolbarFilterButton />}
      {showDensity && <GridToolbarDensitySelector />}
      {showExport && <GridToolbarExport />}
    </Stack>
  </GridToolbarContainer>
);
