"use client";

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import {
  DataGridPro,
  useGridApiRef,
  DataGridProProps,
  nlNL,
  GridFooter,
  GridRowModel,
} from "@mui/x-data-grid-pro";
import { Stack, SxProps, Theme, lighten } from "@mui/material";
import NoDataComponent from "./NoDataComponent";
import { useTheme } from "@cbex/utils/theme";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { CustomToolbar } from "./CustomToolbar";

type CustomDataGridProps = DataGridProProps & {
  width: any;
  getCustomID?: any;
  height: any;
  onRowEdit?: any;
  externalApiRef?: React.MutableRefObject<GridApiPro>;
  sx?: SxProps<Theme>;
  showExport?: boolean;
  showDensity?: boolean;
  showFilter?: Boolean;
  showColumns?: Boolean;
  showQuickFilter?: boolean;
  toolBarChildren?: React.ReactNode;
};

export const CustomDataGrid = (props: CustomDataGridProps) => {
  const {
    sx,
    height,
    width,
    rowHeight,
    getCustomID,
    externalApiRef,
    showExport,
    showDensity,
    showFilter = false,
    showColumns,
    toolBarChildren,
    onRowEdit,
    showQuickFilter = true,
    columnHeaderHeight,
  } = props;

  const internalApiRef = useGridApiRef();
  const apiRef = externalApiRef ? externalApiRef : internalApiRef;
  const localLanguage = () =>
    nlNL.components.MuiDataGrid.defaultProps.localeText;
  const handleRowEditCommit = React.useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      onRowEdit(newRow, oldRow);
      apiRef.current.stopRowEditMode({ id: oldRow.id });
      return newRow;
    },
    [apiRef, onRowEdit]
  );

  const theme = useTheme();
  return (
    <Stack
      id="DataGridComponent"
      width={width ? width : "100%"}
      height={height ? height : "100%"}
    >
      <DataGridPro
        localeText={localLanguage()}
        getRowId={getCustomID ? getCustomID : undefined}
        apiRef={apiRef}
        disableColumnMenu
        columnHeaderHeight={columnHeaderHeight ? columnHeaderHeight + 10 : 50}
        rowHeight={rowHeight}
        processRowUpdate={handleRowEditCommit}
        {...props}
        sx={[
          {
            borderRadius: 0,
            borderLeft: 0,
            borderRight: 0,
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.appColors.surfaceContainerLow,
              borderRadius: 0,
              width: "100%",
            },
            // "& .MuiDataGrid-pinnedColumnHeaders": {
            //   // backgroundColor: theme.appColors.dataGridHeader,
            //   borderRadius: 0,
            // },
            // "& .MuiDataGrid-pinnedColumns": {
            //   // backgroundColor: theme.appColors.dataGridHeader,
            //   borderRadius: 0,
            // },
            // "& .MuiDataGrid-headerFilterRow": {
            //   // backgroundColor: theme.appColors.dataGridHeader,
            //   borderRadius: 0,
            //   border: "none",
            // },
            // "& .MuiDataGrid-footerContainer": {
            //   // backgroundColor: lighten(theme.appColors.dataGridHeader, 0.4),
            //   minHeight: 40,
            // },
            // "& .MuiDataGrid-row.Mui-selected ": {
            //   backgroundColor: lighten(theme.palette.primary.light, 0.4),
            //   cursor: "pointer",
            // },
            // "& .MuiDataGrid-row.Mui-selected:hover ": {
            //   backgroundColor: lighten(theme.palette.primary.light, 0.2),
            //   cursor: "pointer",
            // },
            // "& .MuiDataGrid-row:hover": {
            //   backgroundColor: lighten(theme.palette.primary.light, 0.2),
            //   cursor: "pointer",
            // },
            // "& .MuiDataGrid-cell:focus": {
            //   outline: "none",
            // },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        slots={{
          toolbar: CustomToolbar,
          footer: GridFooter,
          noRowsOverlay: NoDataComponent,
          ...props.slots,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: showQuickFilter,
            quickFilterProps: { debounceMs: 500 },
            showExport: showExport || false,
            showDensity: showDensity || false,
            showFilter: showFilter || false,
            showColumns: showColumns || false,
            children: toolBarChildren || null,
          },
          panel: {
            placement: "bottom-end",
            sx: {
              "& .MuiPaper-root": {
                // backgroundColor: theme.appColors.surfaceContainerHigh,
              },
            },
          },
          ...props.slotProps,
        }}
        editMode="row"
      />
    </Stack>
  );
};

export default CustomDataGrid;
