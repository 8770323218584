import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OutlinedInput from "@mui/material/OutlinedInput";

/* eslint-disable-next-line */
//@ts-ignore
export interface StyledInputProps extends OutlinedInputProps {
  label?: string;
  id?: string;
  typ?: string;
  startAdornment?: any;
  searchTerm: string;
  onClick: any;
  onCloseCLick: any;
}

export const SearchBar = (props: StyledInputProps) => {
  const { onClick, searchTerm, onCloseCLick, ...rest } = props;
  return (
    <Box
      sx={{
        justifyContent: "flex-end",
        alignItems: "flex-end",
        display: "flex",
        marginTop: "px",
      }}
    >
      <FormControl
        sx={{
          m: 1,
          width: "50ch",
        }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          //@ts-ignore
          onChange={onClick}
          value={searchTerm}
          type="text"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon
                sx={{ color: "action.active", fontSize: 17.5, mr: 1, my: 0.5 }}
              />
            </InputAdornment>
          }
          endAdornment={
            searchTerm !== "" && (
              <InputAdornment position="end">
                <Stack style={{}}>
                  <IconButton
                    onClick={onCloseCLick}
                    sx={{
                      display: "flex",
                      width: "14px",
                      alignSelf: "flex-start",
                      height: "14px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CloseIcon
                      sx={{ color: "action.active", fontSize: 17.5 }}
                    />
                  </IconButton>
                </Stack>
              </InputAdornment>
            )
          }
          label="Search..."
        />
      </FormControl>
    </Box>
  );
};

export default SearchBar;
