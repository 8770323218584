import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTranslations } from "next-intl";

interface CustomAlertDialogProps {
  open: boolean;
  heading: string;
  handleClosePressed: () => void;
  handleCancel: () => void;
}

export const CustomAlertDialog = (props: CustomAlertDialogProps) => {
  const { open, heading, handleClosePressed, handleCancel } = props;
  const [openDialog, setOpenDialog] = React.useState(open);
  const t = useTranslations();

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setOpenDialog(!open);
    handleClosePressed();
  };

  const handleCancelPressed = () => {
    handleCancel();
  };

  return (
    <Box id="CustomAlertDialog">
      <Dialog
        open={openDialog}
        onClose={handleCancelPressed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelPressed}>{t("decline")}</Button>
          <Button onClick={handleClose} autoFocus>
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default CustomAlertDialog;
