import React, { ReactNode } from "react";
import { SelectProps, Select } from "@mui/material";

type CustomSelectComponentProps = SelectProps & {
  controldisabled?: boolean;
  useFullWidth?: boolean;
  children: ReactNode;
  size?: string;
};

// const StyledSelect = styled(Select)(({ theme }) => ({
//   "&.MuiFilledInput-root .Mui-disabled ": {
//     // backgroundColor: ,
//     // color: lighten(theme.appColors.blackText, 0.4),
//     pointerEvents: "unset",
//     "&&:hover": {
//       cursor: "default",
//     },
//   },
//   "& .MuiSvgIcon-root": {
//     right: "unset",
//     left: "7px",
//     color: "black",
//     backgroundColor: "black",
//   },
// }));

export const CustomSelectComponent = ({
  size = "small",
  useFullWidth = true,
  controldisabled,
  children,
  ...others
}: CustomSelectComponentProps) => (
  <Select
    id="CustomSelect"
    disabled={controldisabled}
    variant="filled"
    fullWidth={useFullWidth ? true : false}
    size={size}
    {...others}
  >
    {children}
  </Select>
);

export default CustomSelectComponent;
