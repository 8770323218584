import React, { ReactNode } from "react";
import { InputLabel, InputLabelProps, Box } from "@mui/material";

type CustomInputLabelProps = InputLabelProps & {
  children?: ReactNode;
};

export const CustomInputLabel = (props: CustomInputLabelProps) => (
  <Box id="CustomInputLabel">
    <InputLabel {...props} />
  </Box>
);

export default CustomInputLabel;
