import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";
import { GridOverlay } from "@mui/x-data-grid-pro";
export const NoDataComponent = function () {
  const t = useTranslations();
  return (
    <GridOverlay id="NoDataComponent">
      <Box alignItems="center" justifyContent="center" width={"100"}>
        <Typography variant="h4">{t("emptyTree")}</Typography>
      </Box>
    </GridOverlay>
  );
};

export default NoDataComponent;
