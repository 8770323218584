import React, { useEffect } from "react";
import { CustomCheckBoxComponent } from "@cbex/ui/input";
import { CustomFormControlLabelComponent } from "@cbex/ui/text";
import { Stack } from "@mui/material";
import {
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: (__event?: React.SyntheticEvent) => void;
  onCheckBoxClicked: () => void;
  value: string;
  checkBoxValue?: boolean;
  checkBoxLabel?: string;
  SecondcheckBoxValue?: boolean;
  SecondcheckBoxLabel?: string;
  onSecondCheckBoxClicked?: () => void;
  exportEnabled: boolean;
}

export const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
  const { checkBoxValue, SecondcheckBoxValue, value, exportEnabled } = props;
  const [searchValue, setSearchValue] = React.useState(value);
  const [checkboxStateValue, setCheckboxStateValue] =
    React.useState(checkBoxValue);
  const [secondCheckboxStateValue, setSecondCheckboxStateValue] =
    React.useState(SecondcheckBoxValue);

  React.useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const handleCheckboxClicked = () => {
    setCheckboxStateValue(!checkBoxValue);
    //@ts-ignore
    props.onCheckBoxClicked(!checkBoxValue);
  };

  const handleSearchBoxChange = (__event?: React.SyntheticEvent) => {
    //@ts-ignore
    setSearchValue(__event.target.value);
    props.onChange(__event);
  };

  const handleSecondCheckboxClicked = () => {
    setSecondCheckboxStateValue(!secondCheckboxStateValue);
    //@ts-ignore
    props.onSecondCheckBoxClicked(!secondCheckboxStateValue);
  };
  return (
    <Stack id="ToolBar" direction={"row"} spacing={2} padding={2}>
      <Stack width={"100%"} direction={"row"} alignItems={"center"}>
        <GridToolbarContainer>
          {exportEnabled && (
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{
                delimiter: ";",
              }}
            />
          )}
        </GridToolbarContainer>
      </Stack>
      <Stack
        width={"100%"}
        direction={"row"}
        alignItems={"end"}
        justifyContent={"end"}
      >
        {props.SecondcheckBoxLabel && (
          <CustomFormControlLabelComponent
            control={
              <CustomCheckBoxComponent
                controldisabled={false}
                onChange={handleSecondCheckboxClicked}
                checked={secondCheckboxStateValue}
                name={props.SecondcheckBoxLabel}
              />
            }
            label={props.SecondcheckBoxLabel}
          ></CustomFormControlLabelComponent>
        )}
        {props.checkBoxLabel && (
          <CustomFormControlLabelComponent
            control={
              <CustomCheckBoxComponent
                controldisabled={false}
                onChange={handleCheckboxClicked}
                checked={checkboxStateValue}
                name={props.checkBoxLabel}
              />
            }
            label={props.checkBoxLabel}
          ></CustomFormControlLabelComponent>
        )}
        <GridToolbarQuickFilter />
        {/* <TextField
          variant="standard"
          value={searchValue}
          onChange={handleSearchBoxChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: "auto",
              sm: "auto",
            },
            m: (theme) => theme.spacing(1, 0, 0),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        /> */}
      </Stack>
    </Stack>
  );
};

export default QuickSearchToolbar;
