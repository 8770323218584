import React, { ReactNode } from "react";
import { FormControlLabel, FormControlLabelProps, Box } from "@mui/material";

type CustomInputLabelProps = FormControlLabelProps & {
  children?: ReactNode;
};

export const CustomFormControlLabelComponent = (
  props: CustomInputLabelProps
) => (
  <Box id="CustomFormControlLabelComponent">
    <FormControlLabel
      sx={{
        cursor: "default",
      }}
      {...props}
    />
  </Box>
);

export default CustomFormControlLabelComponent;
